import Popup from 'reactjs-popup';
import OptionModel from './OptionModel';

const OrderList = ({ list }) => {
  return (
    <div className={`order_list`}>
      {list &&
        list.map((item, index) => {
          return (
            <Popup
              modal
              nested
              key={`${item.menuName}_${index}`}
              trigger={
                <button className={`order_item`} key={index}>
                  <span className={`order_item_img`}>
                    <img src={`jaws/${item.menuId}.png`} />
                  </span>
                  <span className={`order_item_content`}>
                    <span className={`order_item_name`}>{item.menuName}</span>
                    <span className={`order_item_name`}>{item.quantity}</span>
                  </span>
                </button>
              }
            >
              {(close) => <OptionModel close={close} menu={item} />}
            </Popup>
          );
        })}
    </div>
  );
};

export default OrderList;
