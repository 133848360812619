import jaws from '../data/jaws.json';

const changeKorCategory = (category) => {
  const engToKor = {
    'COFFEE': '커피',
    'NON-COFFEE': '논커피',
    'TEA': '티',
    'ADE': '에이드',
    'SMOOTHIE': '스무디',
    'FRUIT JUCIE': '과일주스',
    'FRAPPE / BUBBLE / SHAKE': ' 프라페/버블티/쉐이크',
    'BAKERY': '베이커리',
    'ECT': '옵션',
    'BOTTLE': '병',
    'SPECIALTY DRINK': '스페셜 드링크',
    '한과': '한과',
  };

  let newMenuGroup = [];
  for (const cate of category) {
    if (engToKor[cate]) {
      newMenuGroup.push(engToKor[cate]);
    } else {
      newMenuGroup.push(cate.trim());
    }
  }
};

export const hasPayment = (data) => {
  if (!data.command) {
    return false;
  }

  const { resultCode, commandList } = data.command;
  if (resultCode === 200) {
    if (commandList.length > 0) {
      const { code } = commandList[0];
      if (code === 'FL150' || code === 'FL151') {
        //안내멘트 재생 "주문이 완료되었습니다"
        //화면이동 및 주문 API
        console.log('주문이 완료되었습니다.');
        return true;
      }
    }
    return false;
  }
  return false;
};

// orderType = {
//   orderType : 'delivery or packing',
//   paymentMethod : 'CARD or CASH',
//   // not required, but if orderType is delivery, this key require
//   customerAddress : { postal : '' , detail : ''}
// }
export const orderMenu = (menuList, orderType = {}) => {
  if (menuList.length > 0) {
    const items = [];
    //make order list to AI order call format
    menuList.map((menu) => {
      const mappedId = jaws[menu.menuId];
      items.push({
        menuGroupId: 'lhzn4eel',
        menuId: mappedId.id,
        priceId: mappedId.priceId,
        quantity: menu.quantity,
      });
    });

    const body = {
      customerPhoneNumber: '01048747896',
      customerAddress: {
        postal: '서울 중구 동호로 249',
        detail: '신라호텔 305호',
      },
      orderItem: {
        items: items,
        paymentMethod: 'CARD',
        orderBy: 'BOT',
        setMenu: [],
      },
      memo: '',
      orderType: 'DELIVERY',
    };

    // const body = {
    //   customerPhoneNumber: '01048747896',
    //   customerAddress: {
    //     postal: '',
    //     detail: '',
    //   },
    //   orderItem: {
    //     items: items,
    //     paymentMethod: orderType.paymentMethod,
    //     orderBy: 'BOT',
    //     setMenu: [],
    //   },
    //   memo: '',
    //   orderType: orderType.orderType,
    // };

    fetch('https://api.aicallorder.com/v1/hansol/store/koc57ybv/order', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'x-api-key': '3cc5abbc5e2e497db70731661d96d812',
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        console.log('res', res);
      })
      .catch((error) => {
        console.log('error', error);
      });
  } else {
    alert('주문할 메뉴가 없습니다');
  }
};

const catchTranscriptResult = (transcript) => {
  if (transcript.includes('포장') || transcript.includes('먹고')) {
    return { next: true, transcript, url: './order?status=packing&language=ko' };
  } else {
    return { next: false, transcript };
  }
};

const fixTranscript = (transcript) => {
  const returnVal = catchTranscriptResult(transcript);
  // setStr1(returnVal.transcript);

  if (returnVal) {
    if (returnVal.next) {
      window.location.href = returnVal.url;
    } else {
      // voiceGuideWithText(voiceInfo[1], setAiStt);
    }
  }
};
