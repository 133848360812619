import React from 'react';
const Flags = ({ getFlags }) => {
  const clickFlags = (e) => {
    const flags = e.target.className;
    if (flags !== 'flags') {
      getFlags(flags);
    }
  };

  return (
    <div className="flags" onClick={clickFlags}>
      <div className="ko"></div>
      <div className="en"></div>
      <div className="ja"></div>
      <div className="zh"></div>
    </div>
  );
};

export default Flags;
