import { useNavigate } from 'react-router-dom';

const TogoOrDinein = ({ language, text }) => {
  const navigate = useNavigate();
  return (
    <div className={`picking_eating`}>
      <span className={`picking`}>
        <div
          onClick={() => {
            navigate(`/order?status=packing&language=${language}`);
          }}
        >
          {text.togo}
        </div>
      </span>
      <span className={`eating`}>
        <div
          onClick={() => {
            navigate(`/order?status=packing&language=${language}`);
          }}
        >
          {text.here}
        </div>
      </span>
    </div>
  );
};

export default TogoOrDinein;
