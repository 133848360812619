import { useState, useEffect, useRef } from 'react';
import { UserSocket, IntroAiStt } from './Stt';
import TogoOrDinein from './TogoOrDineIn';
import Flags from './Flags';
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage, resetTranscript } from '../../redux/kioskSlice';
import { playVoiceGuideWithText } from '../../utils/voiceGuideWithText';
import { useNavigate } from 'react-router-dom';
import { socketAndRecorder } from '../../utils/socketHandler';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const navigate = useNavigate();
  const [isPop, setIsPop] = useState(false);
  const [aiStt, setAiStt] = useState('');
  const [isUser, setIsUser] = useState(false);
  const [isTouchOrder, setIsTouchOrder] = useState(false);
  const [isCard, setIsCard] = useState({
    isPop: false,
    isPassingPerson: false,
  });
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.kiosk);
  const { t, i18n } = useTranslation();

  const getFlags = (flags) => {
    dispatch(changeLanguage({ language: flags }));
    i18n.changeLanguage(flags);
    setIsPop(false);
    setIsUser(false);
    socketAndRecorder.sendStop();
  };

  useEffect(() => {
    if (isPop) {
      setIsCard({
        ...isCard,
        isPop: false,
      });
      socketAndRecorder.sendMessageConfig(language);
      playVoiceGuideWithText('togoorin', language, setAiStt, setIsUser);
    }
  }, [isPop]);

  useEffect(() => {
    if (isCard.isPop) {
      setTimeout(() => {
        //지나가는 사람이 아니면?
        if (!isCard.isPassingPerson) {
          setIsPop(true);
        } else {
          //지나가는 사람이면?
          setIsCard({
            isPop: false,
            isPassingPerson: false,
          });
        }
      }, 1000);
    }
  }, [isCard]);

  const repeatPlayVoiceGuideAndConfig = () => {
    setTimeout(() => {
      playVoiceGuideWithText('togoorin', language, setAiStt);
      socketAndRecorder.sendMessageConfig(language);
    }, 0);
  };

  useEffect(() => {
    //lanuger가 바뀔때마다 음성파일이 변경되야함..
    socketAndRecorder.onMessageReceived = (res) => {
      if (res.code === 200) {
        if (res.data.data.command) {
          //output은 있지만, commandList가 없는 경우
          if (res.data.data.command.commandList <= 0) {
            //PVGS, recorder 오류가 나지 않지만 비동기로 전달하지 않으면 음성인식이 안됨
            repeatPlayVoiceGuideAndConfig();
          } else {
            const { code } = res.data.data.command.commandList[0];
            if (code === 'order_type') {
              navigate(`./order?status=packing&language=${language}`);
            } else {
              //commandList가 있지만, code가 order_type이 아닌 경우
              repeatPlayVoiceGuideAndConfig();
            }
          }
        } else {
          //output='', 사람이 발화를 하지 않은 경우
          repeatPlayVoiceGuideAndConfig();
        }
      }
    };
  }, [language]);

  useEffect(() => {
    console.log('Render Home Component');
    return () => {
      dispatch(resetTranscript());
      console.log('Clear Home Component');
    };
  }, []);

  return (
    <div className={`container`}>
      <div
        className="hidden_path"
        onClick={() => {
          navigate(`/order?status=packing&language=${language}`);
          // window.location.href = './order?status=packing&language=ko';
        }}
      ></div>
      <div className={`intro_wrapper ${isTouchOrder ? 'togoOrDinein' : ''}`}>
        <Flags getFlags={getFlags} />
        {isTouchOrder && (
          <div
            style={{
              width: '55%',
              height: '10vh',
            }}
          ></div>
        )}
        {isCard.isPop && <div className="popup_card"></div>}
        {isPop && (
          <div className="popup">
            <div className="chat">
              {isPop && <IntroAiStt stt={aiStt} />}
              {isUser && <UserSocket />}
            </div>
            <button
              className="touch_btn"
              onClick={() => {
                setIsTouchOrder(!isTouchOrder);
                // setIsPop(false);
                socketAndRecorder.sendStop();
              }}
            >
              {t('touchOrder')}
            </button>
          </div>
        )}
        <div className="sensor_wrap">
          <div
            className="sensor"
            onClick={() => {
              setIsCard({
                ...isCard,
                isPop: !isCard.isPop,
              });
            }}
          >
            {t('sensor')}
          </div>
        </div>

        {isTouchOrder && (
          <TogoOrDinein
            language={language}
            text={{
              togo: t('togo'),
              here: t('here'),
            }}
          />
        )}
      </div>
    </div>
  );
};
export default Home;
