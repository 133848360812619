import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  ko: {
    translation: {
      home: '처음으로',
      cancel_all: '전체 취소',
      cash: '현금',
      card: '카드',
      quantity: '수량',
      voiceOrder: '음성으로 주문 할래요',
      orderAi: '주문할 메뉴를 말해주세요',
      sensor: '근접센서 작동',
      touchOrder: '터치로 주문 할래요',
      togo: '포장하기',
      here: '먹고가기',
    },
  },
  en: {
    translation: {
      home: 'HOME',
      cancel_all: 'cancel all',
      cash: 'cash',
      card: 'card',
      quantity: 'quantity',
      voiceOrder: 'order by voice',
      orderAi: 'tell me the menu',
      sensor: 'SENSOR ON',
      touchOrder: 'order by touch',
      togo: 'To-go',
      here: 'For here',
    },
  },
  ja: {
    translation: {
      home: 'ホーム',
      cancel_all: 'すべてキャンセル',
      cash: '現金',
      card: 'カード',
      quantity: '数量',
      voiceOrder: '音声で注文',
      orderAi: 'メニューを教えて',
      sensor: 'センサーオン',
      touchOrder: 'タッチで注文',
      togo: '持ち帰り',
      here: 'こちらで',
    },
  },
  zh: {
    translation: {
      home: '首页',
      cancel_all: '全部取消',
      cash: '现金',
      card: '信用卡',
      quantity: '数量',
      voiceOrder: '说话点单',
      orderAi: '告诉我选项',
      sensor: '传感器开启',
      touchOrder: '触摸点单',
      togo: '打包',
      here: '堂食',
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'ko',
  bindI18n: 'languageChanged',
  transEmptyNodeValue: '',
  transSupportBasicHtmlNodes: true,
  transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
  useSuspense: true,
});

export default i18n;
