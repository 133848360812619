import React from 'react';
import { useNavigate } from 'react-router-dom';

const GoHome = ({ text }) => {
  const navigate = useNavigate();
  return (
    <div className="reset">
      <span
        onClick={() => {
          navigate(`/`);
        }}
      >
        {text}
      </span>
    </div>
  );
};

export default GoHome;
