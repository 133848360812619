export const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
export const pmr = process.env.REACT_APP_PMR_API;
export const chatbot = process.env.REACT_APP_CHATBOT_API;
export const secretKey = atob(process.env.REACT_APP_SECRET_KEY);
export const accessKey = atob(process.env.REACT_APP_ACCESS_KEY);
export const pvgs = process.env.REACT_APP_PVGS_API;

export const requiredParms = {
  chatbot: 'menu',
  chatbotType: 'common',
  kioskId: 'pandojawsDemo',
  storeId: 'pandojawsDemo',
  brandId: 'pandojawsDemo',
  storeName: 'kiosk-demo',
};
