import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import '../styles/style.scss';
import Home from './Home/Home';
import OrderSocketVersion2 from './Order/OrderSocketVersion2';
import { useEffect } from 'react';
import { socketAndRecorder } from '../utils/socketHandler';
// import OrderSocket from './Order/OrderSocketVersion1';
// import OrderGoogleStt from './Order/OrderGoogleStt';

const RouteApp = () => {
  useEffect(() => {
    console.log('Render RouteApp');
    socketAndRecorder.socketConnect();
    socketAndRecorder.setRecordRTC();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/order" element={<OrderSocketVersion2 />} />
      </Routes>
    </Router>
  );
};

export default RouteApp;
