import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  kioskBrand: 'pandojawsDemo',
  language: 'ko',
  transcript: '',
  recorderState: '',
};

const kioskSlice = createSlice({
  name: 'kiosk',
  initialState,
  reducers: {
    changeLanguage: (state, action) => {
      state.language = action.payload.language;
    },
    setTranscript: (state, action) => {
      state.transcript = action.payload.transcript;
    },
    resetTranscript: (state) => {
      state.transcript = '';
    },
    changeRecorderState: (state, action) => {
      state.recorderState = action.payload.state;
    },
  },
});

export const { changeLanguage, setTranscript, resetTranscript, changeRecorderState } = kioskSlice.actions;

export default kioskSlice.reducer;
