import { accessKey, secretKey, pvgs } from '../type/const';
import crypto from 'crypto-js';
import { io } from 'socket.io-client';
import { v4 as uuid } from 'uuid';
import { setTranscript, changeRecorderState } from '../redux/kioskSlice';
import store from '../redux/store';
import RecordRTC from 'recordrtc';

class SocketAndRecorderHandler {
  constructor() {
    this.message = {}; //챗봇 response
    this.transcript = '';
    this.socket = io(pvgs, {
      auth: {
        message: `pando${accessKey}`,
        timestamp: Date.now(),
        key: secretKey,
        signature: crypto.enc.Base64.stringify(crypto.HmacSHA256(`pando${accessKey}${Date.now()}`, secretKey)),
      },
      autoConnect: false,
      transports: ['websocket'],
    });
    this.requiredParams = {
      chatbot: 'menu',
      chatbotType: 'common',
      kioskId: 'pandojawsDemo',
      storeId: 'pandojawsDemo',
      brandId: 'pandojawsDemo',
      storeName: 'kiosk-demo',
      callid: uuid(),
      requestId: uuid(),
    };
    this.state = {
      start: false,
    };
    this.socket.on('connect', this.handleConnect);
    this.socket.on('START', this.handleStart);
    this.socket.on('MESSAGE_BI', this.handleMessageBi);
    this.socket.on('MESSAGE_CONFIG_BI', this.handleMessageConfig);
    this.socket.on('MESSAGE', this.handleMessage);
    this.socket.on('STOP', this.handleStop);
    this.socket.on('connect_error', (err) => {
      //fail socket to connect
      console.log(`📢 connect_error due to ${err.message}`);
      console.error(err);
    });
    //recoder
    this.recorder = null;
  }

  socketConnect = () => {
    this.socket.connect();
  };

  handleConnect = () => {
    console.log('connected id', this.socket.id);
    window.localStorage.setItem('callid', this.requiredParams.callid);
    this.socket.emit('START', { requiredParams: this.requiredParams });
  };

  handleStart = (res) => {
    if (res.success) {
      this.state.start = res.success;
    }
  };

  handleMessage = (res) => {
    this.message = res;
    console.log('res', res);
    this.stopRecording();
    this.onMessageReceived(res);
  };

  //MESSAGE_BI 응답 핸들링 -> transcript
  handleMessageBi = ({ transcript }) => {
    this.transcript = transcript;
    store.dispatch(setTranscript({ transcript }));
  };

  handleMessageConfig = (res) => {
    console.log('message config res', res);
    //마이크 on
    if (res.success) {
      this.startRecording();
    }
  };

  handleStop = () => {
    this.stopRecording();
  };

  //컴포넌트마다 재정의해서 사용하는곳
  onMessageReceived = () => {};

  sendStop = () => {
    this.stopRecording();
    this.socket.emit('STOP');
  };

  sendMessageConfig = (language) => {
    if (this.state.start) {
      const fullLang = {
        ko: 'ko-KR',
        en: 'en-US',
        ja: 'ja-JP',
        zh: 'zh-CN',
      };
      const msgConfigData = {
        requiredParams: {
          ...this.requiredParams,
          requestId: uuid(),
        },
        sttOptions: {
          language_code: fullLang[language],
          alternative_language_codes: ['en-US', 'ja-JP', 'zh-CN'],
          encoding: 'WEBM_OPUS',
          sampleRateHertz: 48000,
        },
      };

      this.socket.emit('MESSAGE_CONFIG_BI', msgConfigData);
    } else {
      // 첫 화면으로 전환 필요
      // window.history.back();
      window.location.href = './';
    }
  };

  disconnect = () => {
    this.socket.off('message', this.handleMessage);
    this.socket.disconnect();
  };

  //recorder handle function
  setRecordRTC = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const recorder = RecordRTC(stream, {
        type: 'audio',
        mimeType: 'audio/webm',
        sampleRate: 48000,
        timeSlice: 100,
        ondataavailable: (blob) => {
          this.socket.emit('MESSAGE_AUDIO', blob);
        },
      });
      this.recorder = recorder;
    });
  };

  startRecording = () => {
    if (this.recorder) {
      this.recorder.startRecording();
      this.changeState();
    } else {
      console.log('recorder is null');
    }
  };

  stopRecording = () => {
    if (this.recorder) {
      this.recorder.stopRecording();
      this.changeState();
    } else {
      console.log('recorder is null');
    }
  };

  changeState = () => {
    const state = this.recorder.getState();
    store.dispatch(changeRecorderState({ state }));
  };
}

export const socketAndRecorder = new SocketAndRecorderHandler();
