import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const UserStt = ({ results }) => {
  const result = results.length > 0 ? results[results.length - 1].transcript : '';
  return <div className={'user_stt'}>{`${result}`}</div>;
};

const UserSocket = () => {
  const { transcript, recorderState } = useSelector((state) => state.kiosk);
  const [isMicOff, setIsMicOff] = useState(null);
  useEffect(() => {
    switch (recorderState) {
      case 'recording':
        setIsMicOff(false);
        break;
      case 'stopped':
        setIsMicOff(true);
        break;
    }
  }, [recorderState]);
  return (
    <div className={`user stt`}>
      {transcript}
      <div className={`${isMicOff ? 'mic_off' : 'mic_on'}`}></div>
    </div>
  );
};

const IntroAiStt = ({ stt }) => {
  return <div className={'ai stt'}>{stt}</div>;
};

export { UserStt, UserSocket, IntroAiStt };
