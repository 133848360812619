import { createSlice } from '@reduxjs/toolkit';
import * as type from './actionType';

const initialState = {
  playing: type.READY,
};

const voiceSlice = createSlice({
  name: 'voice',
  initialState,
  reducers: {
    startPlaying: (state) => {
      state.playing = type.PLAY;
    },
    playOver: (state) => {
      state.playing = type.READY;
    },
  },
});

export const { startPlaying, playOver } = voiceSlice.actions;

export default voiceSlice.reducer;
