//socket type
export const START = 'START';
export const MESSAGE = 'MESSAGE';
export const MESSAGE_BI = 'MESSAGE_BI';
export const MESSAGE_AUDIO = 'MESSAGE_AUDIO';
export const END = 'END';
export const STOP = 'STOP';
//howler type
export const PLAY = 'PLAY';
export const READY = 'READY';
