import { Howl } from 'howler';
import store from '../redux/store';
import { startPlaying, playOver } from '../redux/voiceSlice';
import * as type from '../redux/actionType';

//voice module
import TOGOORHERE from '../assets/voice/kiosk_please_say_eat_and_go_or_packing.webm';
import TOGOORHERE_EN from '../assets/voice/kiosk_please_say_eat_and_go_or_packing_en.webm';
import TOGOORHERE_JA from '../assets/voice/kiosk_please_say_eat_and_go_or_packing_jp.webm';
import TOGOORHERE_ZH from '../assets/voice/kiosk_please_say_eat_and_go_or_packing_ch.webm';
import TELLME from '../assets/voice/kiosk_tell_me_the_order_menu.webm';
import TELLME_EN from '../assets/voice/kiosk_tell_me_the_order_menu_en.webm';
import TELLME_JA from '../assets/voice/kiosk_tell_me_the_order_menu_jp.webm';
import TELLME_ZH from '../assets/voice/kiosk_tell_me_the_order_menu_ch.webm';
import ADDMENU from '../assets/voice/kiosk_please_tell_me_the_menu_to_add_or_tell_me_the_payment_method.webm';
import ADDMENU_EN from '../assets/voice/kiosk_please_tell_me_the_menu_to_add_or_tell_me_the_payment_method_en.webm';
import ADDMENU_JA from '../assets/voice/kiosk_please_tell_me_the_menu_to_add_or_tell_me_the_payment_method_jp.webm';
import ADDMENU_ZH from '../assets/voice/kiosk_please_tell_me_the_menu_to_add_or_tell_me_the_payment_method_ch.webm';
import END from '../assets/voice/kiosk_default_thankyou.webm';
import END_EN from '../assets/voice/kiosk_default_thankyou_en.webm';
import END_JA from '../assets/voice/kiosk_default_thankyou_jp.webm';
import END_ZH from '../assets/voice/kiosk_default_thankyou_ch.webm';

const voiceInfo = {
  togoorin: {
    ko: {
      ms: 3000,
      script: '먹고가기 또는 포장하기라고 말씀해주세요',
      voiceSrc: TOGOORHERE,
    },
    en: {
      ms: 1800,
      script: 'Say For here Or To-go',
      voiceSrc: TOGOORHERE_EN,
    },
    ja: {
      ms: 4000,
      script: '持ち帰りかこちらで',
      voiceSrc: TOGOORHERE_JA,
    },
    zh: {
      ms: 3000,
      script: '外带还是堂食',
      voiceSrc: TOGOORHERE_ZH,
    },
  },
  tellme: {
    ko: {
      ms: 0,
      script: '',
      voiceSrc: TELLME,
    },
    en: {
      ms: 0,
      script: '',
      voiceSrc: TELLME_EN,
    },
    ja: {
      ms: 0,
      script: '',
      voiceSrc: TELLME_JA,
    },
    zh: {
      ms: 0,
      script: '',
      voiceSrc: TELLME_ZH,
    },
  },
  addmenu: {
    ko: {
      ms: 0,
      script: '',
      voiceSrc: ADDMENU,
    },
    en: {
      ms: 0,
      script: '',
      voiceSrc: ADDMENU_EN,
    },
    ja: {
      ms: 0,
      script: '',
      voiceSrc: ADDMENU_JA,
    },
    zh: {
      ms: 0,
      script: '',
      voiceSrc: ADDMENU_ZH,
    },
  },
  end: {
    ko: {
      ms: 0,
      script: '',
      voiceSrc: END,
    },
    en: {
      ms: 0,
      script: '',
      voiceSrc: END_EN,
    },
    ja: {
      ms: 0,
      script: '',
      voiceSrc: END_JA,
    },
    zh: {
      ms: 0,
      script: '',
      voiceSrc: END_ZH,
    },
  },
};

let sound = '';

export const playVoiceGuideWithText = (type, language, setStt, setUser) => {
  const { playing } = store.getState().voice;

  if (playing === type.PLAY) {
    sound.stop();
    console.log('⛔Change Voice Guide⛔');
  }

  sound = new Howl({
    src: [voiceInfo[type][language].voiceSrc],
    volume: 1.0,
    html5: true,
    onplayerror: function (error) {
      alert('failed play the voice');
      console.log('play error', error);
    },
    onend: function () {
      console.log('finished the voice guide');
      store.dispatch(playOver());
      if (setUser) {
        setUser(true);
      }
    },
  });

  sound.play();
  store.dispatch(startPlaying());

  if (setStt) {
    const scriptArr = voiceInfo[type][language].script.split('');
    const intervalTime = parseInt((voiceInfo[type][language].ms / scriptArr.length).toFixed(2));

    let index = 0;
    let str = '';
    const interval = setInterval(() => {
      str += voiceInfo[type][language].script[index];
      index++;
      setStt(str);
      if (index === scriptArr.length) {
        clearInterval(interval);
      }
    }, intervalTime);
  }
};
