import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CancelIcon from '@mui/icons-material/Cancel';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AddCardIcon from '@mui/icons-material/AddCard';
import axios from 'axios';
import _ from 'lodash';
import OrderList from './OrderList';
import { pmr, requiredParms as parmasData } from '../../type/const';
import GoHome from '../../components/GoHome';
import { hasPayment, orderMenu } from '../../utils/functions';
import { useDispatch, useSelector } from 'react-redux';
import { playVoiceGuideWithText } from '../../utils/voiceGuideWithText';
import { socketAndRecorder } from '../../utils/socketHandler';
import { resetTranscript } from '../../redux/kioskSlice';
import { useTranslation } from 'react-i18next';
const OrderSocketVersion2 = () => {
  const { language, transcript, recorderState } = useSelector((state) => state.kiosk);
  const [selectedMenuList, setSelectedMenuList] = useState([]);
  const [menuGroupList, setMenuGroupList] = useState([]);
  const [selectedMenuGroup, setSelectedMenuGroup] = useState('');
  const [viewMenuList, setViewMenuList] = useState([]);
  const [allMenuData, setAllMenuData] = useState([]);
  const [payment, setPayment] = useState(false);
  const [total, setTotal] = useState(0);
  const [menuNavigate, setMenuNavigate] = useState({
    limit: 8,
    data: [],
    indexRange: { start: 0, last: 8 },
    currentIdxRange: 0,
  });
  const [isNotUseVoiceOrder, setIsNotUseVoiceOrder] = useState(false);
  const [noResponseTime, setNoResponseTime] = useState(null);
  const [isMicOff, setIsMicOff] = useState(null);

  const { t } = useTranslation();

  const voiceType = useRef('');
  const originMenuGroup = useRef([]);
  const menuList = useRef([]);
  const dispatch = useDispatch();
  const navigator = useNavigate();

  const getMenuData = async () => {
    const res = await axios.get(`${pmr}/brands/clean/${parmasData.brandId}?language=${language}`);
    setAllMenuData(_.get(res, 'data'));
    setSelectedMenuGroup(_.get(res, 'data')[0].menuGroupName);
    let menutempList = _.get(res, 'data');
    let menuGroup = _.map(_.uniqBy(menutempList, 'menuGroupName'), 'menuGroupName');
    console.log('menuGroup', menuGroup);
    const totalIdxRange = Math.ceil(menuGroup.length / menuNavigate.limit);
    let indexRange = {};
    for (let i = 0; i < totalIdxRange; i++) {
      let last = menuGroup.length <= (i + 1) * menuNavigate.limit ? menuGroup.length : (i + 1) * menuNavigate.limit;
      indexRange[i] = { start: i * menuNavigate.limit, last };
    }
    setMenuNavigate({
      ...menuNavigate,
      data: menuGroup.filter((_, idx) => idx < menuNavigate.limit),
      indexRange,
    });
    setMenuGroupList([...menuGroup]);
  };

  const clickArrow = (type) => {
    const { indexRange, currentIdxRange } = menuNavigate;
    let start = 0;
    let last = 0;
    let nowIdxRange = 0;

    if (type === 'prev') {
      nowIdxRange = currentIdxRange - 1;
    }
    if (type === 'next') {
      nowIdxRange = currentIdxRange + 1;
    }

    if (nowIdxRange in indexRange) {
      start = indexRange[nowIdxRange].start;
      last = indexRange[nowIdxRange].last;
    } else {
      return;
    }

    const data = menuGroupList.slice(start, last);
    setMenuNavigate({
      ...menuNavigate,
      currentIdxRange: nowIdxRange,
      data,
    });
  };

  const configCommandListUsingChatbotRes = (data) => {
    const { resultCode, commandList } = data.command;
    if (resultCode === 200) {
      if (commandList.length > 0) {
        const { code, value } = commandList[0];
        switch (code) {
          case 'T':
            const selectedOriginCate = originMenuGroup.current.find((menu) => menu === value);
            console.log('selectedOriginCate', selectedOriginCate);
            if (selectedOriginCate) {
              setSelectedMenuGroup(selectedOriginCate);
              return true;
            }
            break;
          case 'cancel_all':
            setSelectedMenuList([]);
            break;
        }
      }
      return false;
    }
    return false;
  };

  const addMenuList = (menu) => {
    const prevList = menuList.current;
    const { menuName, quantity = 1 } = menu;
    const findvalue = _.find(prevList, { menuName });
    if (findvalue) {
      _.update(findvalue, 'quantity', (n) => n + quantity);
    } else {
      prevList.push({ ...menu, quantity });
    }
    setSelectedMenuList([...prevList]);
  };

  const cancelAll = () => {
    console.log('cancel alll menu list');
    setSelectedMenuList([]);
  };

  const voiceOrderOnClickHandler = () => {
    setNoResponseTime(0);
    setIsNotUseVoiceOrder(false);
    playVoiceGuideWithText(voiceType.current, language);
  };

  const clickMenu = (item) => {
    console.log('🌻', selectedMenuList);
    addMenuList(item);
    setNoResponseTime(2);
    socketAndRecorder.sendStop();
  };

  const order = (isPayment) => {
    if (isPayment) {
      if (selectedMenuList.length <= 0) {
        //TODO change Toast Message
        alert('주문할 메뉴가 없습니다');
        return;
      } else {
        setPayment(isPayment);
        playVoiceGuideWithText('end', language);
        //cURL API
        orderMenu(selectedMenuList);
        setTimeout(() => {
          navigator('/');
        }, 5000);
      }
    }
  };

  useEffect(() => {
    if (menuGroupList.length > 0) {
      originMenuGroup.current = menuGroupList;
    }
  }, [menuGroupList]);

  useEffect(() => {
    let viewMenuListTmp = _.filter(allMenuData, { menuGroupName: selectedMenuGroup });
    viewMenuListTmp = _.unionBy(viewMenuListTmp, 'menuName');
    viewMenuListTmp = _.orderBy(viewMenuListTmp, ['menuName'], ['asc']);
    setViewMenuList(viewMenuListTmp);

    if (selectedMenuGroup) {
      const { currentIdxRange } = menuNavigate;
      const index = menuGroupList.findIndex((menu) => menu === selectedMenuGroup);
      if (index < 0) return;

      const { start = 0, last = 0 } = menuNavigate?.indexRange[currentIdxRange];
      let nowIndex = currentIdxRange;

      if (index < start) {
        nowIndex = currentIdxRange - 1;
      } else if (index >= last) {
        nowIndex = currentIdxRange + 1;
      } else {
        return;
      }

      if (nowIndex !== currentIdxRange) {
        const { start, last } = menuNavigate.indexRange[nowIndex];
        const list = menuGroupList.slice(start, last);
        setMenuNavigate({
          ...menuNavigate,
          data: list,
          currentIdxRange: nowIndex,
        });
      }
    }
  }, [selectedMenuGroup]);

  useEffect(() => {
    console.log('selectedMenuList', selectedMenuList);
    menuList.current = selectedMenuList;
    if (selectedMenuList.length <= 0) {
      voiceType.current = 'tellme';
    } else {
      voiceType.current = 'addmenu';
      const total = selectedMenuList.reduce((acc, list) => acc + list.quantity, 0);
      setTotal(total);
    }
  }, [selectedMenuList]);

  useEffect(() => {
    if (noResponseTime !== null) {
      if (noResponseTime >= 2) {
        console.log('turn off the recorder');
        setIsNotUseVoiceOrder(true);
      } else {
        socketAndRecorder.sendMessageConfig(language);
      }
    }
  }, [noResponseTime]);

  useEffect(() => {
    switch (recorderState) {
      case 'recording':
        setIsMicOff(false);
        break;
      case 'stopped':
        setIsMicOff(true);
        break;
    }
  }, [recorderState]);

  useEffect(() => {
    console.log('Render OrderSocket Component');
    getMenuData();
    playVoiceGuideWithText('tellme', language);
    socketAndRecorder.sendMessageConfig(language);

    socketAndRecorder.onMessageReceived = (res) => {
      if (res.code === 200) {
        const { data } = res.data;
        if (!data.hasOwnProperty('menuList')) {
          //발화를 아예 안한 경우
          setNoResponseTime((prev) => {
            return prev ? prev + 1 : 1;
          });
        } else {
          // 인식한 단어가 있는 경우
          const { resultCode } = data; // 메뉴 리스트가 있는 경우 200 아닌 경우 404
          if (resultCode === 200) {
            const { menuList } = data;
            for (const menu of menuList) {
              addMenuList(menu);
            }
            playVoiceGuideWithText('addmenu', language);
          }

          configCommandListUsingChatbotRes(data); // 카테고리 변경
          const isPayment = hasPayment(data);
          if (isPayment) {
            order(isPayment); //결제하는 경우
          } else {
            //결제가 아닌 경우 다시 시작
            console.log('no recognition');
            setTimeout(() => {
              socketAndRecorder.sendMessageConfig(language);
              dispatch(resetTranscript());
            }, 0);
          }
        }
      }
    };

    return () => {
      socketAndRecorder.sendStop();
      dispatch(resetTranscript());
      console.log('Clear OrderSocket Component');
    };
  }, []);

  return (
    <div className={`container`}>
      {/* <h1>Socket version with PVGS</h1> */}
      {payment ? (
        <div className="order_end">
          <img src="finish.png" />
        </div>
      ) : (
        <div className={`wrapper`}>
          {/* 메뉴 그룹 리스트 부분 시작 */}
          <GoHome text={t('home')} />
          <div className={`menu_group_listbox`}>
            <button className={`left_btn`} onClick={() => clickArrow('prev')}>
              <ArrowBackIosIcon />
            </button>
            <div className={`menu_group_itembox`}>
              {menuNavigate.data &&
                menuNavigate.data.map((item, index) => {
                  return (
                    <button
                      className={`menu_group_item ${item === selectedMenuGroup ? 'acitve' : ''}`}
                      key={`menuGroupList_${index}`}
                      onClick={() => setSelectedMenuGroup(item)}
                    >
                      {item}
                    </button>
                  );
                })}
            </div>
            <button className={`right_btn`} onClick={() => clickArrow('next')}>
              <ArrowForwardIosIcon />
            </button>
          </div>
          {/* 메뉴 그룹 리스트 부분 끝 */}
          {/* 메뉴 리스트 부분 시작 */}
          <div className={`menu_listbox`}>
            {viewMenuList &&
              viewMenuList.map((item, index) => {
                return (
                  <button
                    className={`menu_item`}
                    key={`viewMenuList_${index}`}
                    onClick={() => {
                      clickMenu(item);
                    }}
                  >
                    <span className={`menu_img`}>
                      <img src={`jaws/${item.menuId}.png`} alt="이미지없음" />
                    </span>
                    <span className={`menu_content`}>
                      <span className={`menu_name ${isNotUseVoiceOrder && 'not'}`}>{item.menuName}</span>
                    </span>
                  </button>
                );
              })}
          </div>
          {/* 메뉴 리스트 부분 끝 */}
          {/* 주문 리스트 부분 시작 */}
          <div className={`order_listbox`}>
            <OrderList list={selectedMenuList} />
            <div className={`order_status_box`}>
              <span className={`count`}>
                {t('quantity')}: {total}
              </span>
              <span className={`btn_group`}>
                <button className={`cancel_btn`} onClick={cancelAll}>
                  <CancelIcon /> {t('cancel_all')}
                </button>
                <button
                  className={`cash_btn`}
                  onClick={() => {
                    order(true);
                  }}
                >
                  <MonetizationOnIcon />
                  {t('cash')}
                </button>
                <button
                  className={`card_btn`}
                  onClick={() => {
                    order(true);
                  }}
                >
                  <AddCardIcon />
                  {t('card')}
                </button>
              </span>
            </div>
          </div>
          {/* 주문 리스트 부분 끝 */}
          {/* 오디오 부분 시작 */}
          {isNotUseVoiceOrder ? (
            <div className="no_voice audio_switch_box ">
              <div onClick={voiceOrderOnClickHandler}>{t('voiceOrder')}</div>
            </div>
          ) : (
            <div className={`audio_switch_box`}>
              <div className={`audio_text`}>
                <div className="guide_text_wrap">
                  <SmartToyIcon sx={{ fontSize: 55, color: '#63392d' }} />
                  <div className="guide_text">{t('orderAi')}</div>
                </div>
                <div className={`results`}>
                  <span className={`${isMicOff ? 'mic_off' : 'mic_on'}`}></span>
                  {transcript}
                </div>
              </div>
            </div>
          )}
          {/* 오디오 부분 끝 */}
        </div>
      )}
    </div>
  );
};

export default OrderSocketVersion2;
