import { configureStore } from '@reduxjs/toolkit';
import voiceReducer from './voiceSlice';
import kioskReducer from './kioskSlice';
const store = configureStore({
  reducer: {
    voice: voiceReducer,
    kiosk: kioskReducer,
  },
});

export default store;
